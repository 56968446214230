<template>
	<div id="app">
		<Navbar v-if="showNavbar" :key="$store.state.navbarState"></Navbar>
		<router-view />
	</div>
</template>

<script>
import Navbar from '@/components/Navbar';
let agent = navigator.userAgent.toLowerCase();
if ((navigator.appName == 'Netscape' && agent.indexOf('trident') != -1) || agent.indexOf('msie') != -1) {
	// ie일 경우
	window.location.replace('https://nolgongschool.com/browser-support');
}
export default {
	name: 'App',
	components: {
		Navbar,
	},
	computed: {
		showNavbar: () => {
			return !(window.location.pathname.startsWith('/athome') || window.location.pathname.startsWith('/signup') || window.location.pathname.startsWith('/login'));
		},
	},
	mounted() {},
};
</script>

<style>
/* 기본 서식 */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR&display=swap');
html {
	background-color: #f9f9f9;
}
body {
	color: #000000;
	background-color: #f9f9f9;
	margin: 0;
	width: 100%;
	height: 100%;
}
#app {
	font-family: 'Noto Sans KR', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	background-color: #f9f9f9;
	text-align: center;
	color: #000000;
	height: 100%;
	min-width: 320px;
}
a:hover {
	text-decoration: none !important;
}
div:focus {
	outline: none;
}
/* 개별 뷰 (마이페이지, 개별 세션 등) */
.view {
	width: 100%;
	height: 100%;
	background-color: #f9f9f9;
	color: #000000;
	font-size: 1em;
	text-align: center;
	overflow-x: hidden;
}
.view-title {
	font-weight: bold;
	font-size: 1.25rem;
}
.view-description {
	color: gray;
	word-break: keep-all;
}

/* 모듈 컴포넌트 (교사 정보 카드, 세션 프레스코드 카드 등) */
.container {
	/* .home--sessionlist */
	background-color: #fff;
	padding: 1rem 0;
	border-radius: 25px;
	box-shadow: 0 2px 2px 0 #c9c9c9;
	margin-bottom: 2rem;
	min-width: 280px;
}
.container .header {
	/* .home--sessionlist > p */
	border-bottom: 1px solid #d8d8d8;
	padding-bottom: 0.5rem;
	margin-bottom: 0;
	font-weight: bold;
	font-size: large;
}
.container .row {
	text-align: left;
	align-items: center;
	margin: 0 1rem;
	padding-top: 1rem;
}
.container .cell {
	vertical-align: middle;
	margin: 0;
}

.btn {
	line-height: 2em;
}
.input {
	height: 44px;
}

/* 개별 요소 서식용 유틸리티 클래스 */
.bold {
	font-weight: bold;
}
.btn.orange {
	background-color: #ff6936;
	color: #fff;
	border: none;
}
.btn.yellow {
	background-color: #fedb45;
	color: #fff;
	border: none;
}
.btn.green {
	background-color: #00bc70;
	color: #fff;
	border: none;
}
a.green {
	background-color: #00bc70;
	color: #fff;
	border: none;
}

.default-padding {
	padding-left: 15px;
	padding-right: 15px;
}
.no-padding {
	padding: 0;
}
.no-side-padding {
	padding-left: 0;
	padding-right: 0;
}
.no-side-margin {
	margin: auto 0;
}
.hidden {
	visibility: hidden;
}

/*** span 아이콘 ***/
/* IE 대응 */
.icon {
	display: inline-block;
	vertical-align: middle;
	width: 2rem;
	height: 2rem;
	color: #ff6936;
}
.icon-arrow-down::after {
	content: '∨';
}
.icon-arrow-up::after {
	content: '∧';
}
.icon-refresh {
	cursor: pointer;
}
.icon-refresh::after {
	content: ' ↻';
	color: #ff6936;
}
.icon-cloud-download::after {
	content: '⬇';
	color: #ff6936;
}

/* 모던 브라우저 */
@supports (mask-size: cover) {
	.icon::after {
		content: none;
	}
	.icon-arrow-down {
		mask: url(./assets/keyboard_arrow_down-24px.svg);
	}
	.icon-arrow-up {
		mask: url(./assets/keyboard_arrow_up-24px.svg);
	}
	.icon-refresh {
		mask: url(./assets/refresh-24px.svg);
	}
	.icon-cloud-download {
		mask: url(./assets/cloud_download-24px.svg);
	}
	.icon {
		background-color: #ff6936;
		mask-size: cover;
	}
}
@supports (-webkit-mask-size: cover) {
	.icon::after {
		content: none;
	}
	.icon-arrow-down {
		mask: url(./assets/keyboard_arrow_down-24px.svg);
	}
	.icon-arrow-up {
		mask: url(./assets/keyboard_arrow_up-24px.svg);
	}
	.icon-refresh {
		mask: url(./assets/refresh-24px.svg);
	}
	.icon-cloud-download {
		mask: url(./assets/cloud_download-24px.svg);
	}
	.icon {
		background-color: #ff6936;
		mask-size: cover;
	}
}

/* 푸터를 맨 바닥으로 밀기 위한 설정 https://developer.mozilla.org/en-US/docs/Web/CSS/Layout_cookbook/Sticky_footers */
.view {
	flex-direction: column;
	display: flex;
	min-height: 100vh;
}
.main {
	flex-grow: 1;
}
.footer {
	flex-shrink: 0;
}

/* 반응형 서식 */
.main {
	padding-top: calc(1em + 56px);
	padding-bottom: 56px;
	padding-left: 2rem;
	padding-right: 2rem;
	background-color: #fff;
	width: 100%;
	margin: auto;
}
@media (max-width: 1280px) {
	.main {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.main {
		padding-left: 1rem;
		padding-right: 1rem;
	}
	.container {
		max-width: 540px;
	}
	.desktop {
		display: none;
	}
}

@media (min-width: 992px) {
	.mobile {
		display: none;
	}
	.btn {
		max-width: 400px;
	}
}
</style>
