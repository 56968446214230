// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import '@babel/polyfill';
import 'es6-promise/auto';
import Vue from 'vue';
import App from './App';
import router from './router';
import VueGtag from 'vue-gtag';
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import axios from 'axios';
import { store } from './Vuex/store';

/* eslint-disable */

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.prototype.$Axios = axios;
// Vue.prototype.$baseURL = "https://noldev-api.miraeclass.com/";

Vue.use(
	VueGtag,
	{
		config: { id: 'UA-161958269-1' },
	},
	router
);

Vue.config.productionTip = false;

new Vue({
	el: '#app',
	router,
	components: { App },
	template: '<App/>',
	render: (h) => h(App),
	store: store,
	mounted() {
		// You'll need this for renderAfterDocumentEvent.
		document.dispatchEvent(new Event('render-event'));
	},
});

// console.log('%c ༼ つ ◕_◕ ༽つ 놀공스쿨에 오신 것을 환영합니다! ', 'color:#00A700;background:#222')
// console.log('%c        ,-.                                                                  ', 'color:#00A700;')
// console.log('%c     &@@   @@&                                                               ', 'color:#00A700;')
// console.log('%c  &@@         @    @@  @    @@@@    @      ,@@@@     ,@@@@    @@  @  ,@@@@   ', 'color:#00A700;')
// console.log('%c @@          \\ @   @@@ @  @@    @@  @     @@/       @@    @@  @@@ @ @@/      ', 'color:#00A700;')
// console.log('%c @@         \\ %@   @ @@@  @@    @%  @     @@  @@@(  @@    @@% @ @@@ @@  @@@( ', 'color:#00A700;')
// console.log('%c  %@@      \\ \\@    @  @@    @@@@    @@@@@  @@  @@     @@@@    @  @@  @@  @@  ', 'color:#00A700;')
// console.log('%c     &@@  \\ \\@)))                            ***                       ***   ', 'color:#00A700;')
// console.log('%c        ^*^))))))                                                            ', 'color:#00A700;')
// console.log('%c 놀공이 궁금하다면 hello@nolgong.com 으로 연락주세요. ', 'color:#00A700;background:#222')
