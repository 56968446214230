import Vue from 'vue';
import VueRouter from 'vue-router';
import Meta from 'vue-meta';
import { store } from '@/Vuex/store';

/* eslint-disable */
Vue.use(VueRouter);
Vue.use(Meta);

// 내비게이션 가드를 위한 함수
const checkSignIn = () => async (to, from, next) => {
	if (store.getters.tokenValid()) {
		// 토큰이 존재하고 유효하면 다음 단계 진행

		// 요청받은 라우트로 이동
		return next();
	}
	// 위의 조건이 맞지 않으면 로그인 페이지로 보낸다.
	// redirect 파라미터를 설정해서 로그인 후 원래 가려던 페이지로 들어갈 수 있게 한다.
	else return next('/login?redirect=' + to.path);
};

const bypassSignIn = () => async (to, from, next) => {
	// TODO 사전 로그인시 navbar 업데이트 안 되는 이슈
	if (store.getters.tokenValid()) {
		store.commit('UPDATE_NAVBAR');
		// 토큰이 존재하고 유효하면 로그인 절차 생략
		if (to.query.redirect) {
			// redirect 쿼리 파라메터 설정시 해당 라우트로 이동
			return next(to.query.redirect);
		} else {
			// redirect 없을 시 홈으로 이동
			return next('/home');
		}
	} else {
		store.dispatch('SIGNOUT');
		return next();
	}
};

// 로그인, 회원가입 페이지에 들어갈 때는 token 값을 없앤다?
const signOut = () => (to, from, next) => {
	store.dispatch('SIGNOUT');
	return next();
};

const router = new VueRouter({
	mode: 'history',
	routes: [
		{
			path: '/',
			name: 'Landing',
			component: () => import('@/views/Landing'),
		},
		{
			path: '/project1',
			name: 'Project1',
			component: () => import('@/views/Project1'),
		},
		{
			path: '/project4',
			name: 'Project4',
			component: () => import('@/views/Project4'),
		},
		{
			path: '/project5',
			name: 'Project5',
			component: () => import('@/views/Project5'),
		},
		{
			path: '/project6',
			name: 'Project6',
			component: () => import('@/views/Project6'),
		},
		{
			path: '/home',
			name: 'ClassList',
			component: () => import('@/views/ClassList'),
			beforeEnter: checkSignIn(),
		},
		{
			path: '/mypage',
			name: 'Profile',
			component: () => import('@/views/Profile'),
			beforeEnter: checkSignIn(),
		},
		{
			path: '/login',
			name: 'SignIn',
			component: () => import('@/views/SignIn'),
			beforeEnter: bypassSignIn(),
		},
		{
			path: '/signup',
			name: 'SignUp',
			component: () => import('@/views/SignUp'),
			beforeEnter: signOut(),
		},
		{
			path: '/password/reset',
			name: 'PasswordReset',
			component: () => import('@/views/PasswordReset'),
			beforeEnter: signOut(),
		},
		{
			path: '/password/reset/confirm/:uid/:reset_token',
			name: 'PasswordResetConfirm',
			component: () => import('@/views/PasswordResetConfirm'),
			props: true,
			beforeEnter: signOut(),
		},
		{
			path: '/about',
			name: 'About',
			component: () => import('@/views/About'),
		},
		{
			path: '/material',
			name: 'Material',
			component: () => import('@/views/Material'),
			beforeEnter: checkSignIn(),
		},
		{
			path: '/session/:session_id',
			name: 'Session',
			component: () => import('@/views/Session'),
			beforeEnter: checkSignIn(),
			props: true,
		},
		{
			path: '/result/yeoju/:session_id/:player_id',
			name: 'ResultYeoju',
			component: () => import('@/views/ResultYeoju'),
			beforeEnter: checkSignIn(),
			props: true,
		},
		{
			path: '/athome',
			name: 'AtHome',
			component: () => import('@/views/AtHomeRedirect'),
		},
		// {
		//     path: '/athome/wallpeckers',
		//     name: 'Wallpeckers',
		//     component: () => import('@/views/Wallpeckers')
		// },
		{
			path: '/article/:article_id',
			name: 'Article',
			component: () => import('@/components/ArticleModal'),
			props: true,
		},
		{
			path: '/consent',
			name: 'ConsentToUseApp',
			component: () => import('@/views/ConsentToUseApp'),
			props: true,
		},
		{
			path: '/nolauthsignin',
			name: 'NolauthSignIn',
			component: () => import('@/views/NolauthSignIn'),
			props: true,
		},
		{
			path: '/nolauthsignup',
			name: 'NolauthSignUp',
			component: () => import('@/views/NolauthSignUp'),
			props: true,
		},
		{
			path: '*',
			component: () => import('@/views/NotFound'),
		},
	],
});

router.beforeEach((to, from, next) => {
	if (store.getters.refreshValid()) {
		// 갱신 토큰이 있을 경우 무조건 갱신 요청
		store
			.dispatch('REFRESH_TOKEN', {
				refresh: store.state.refresh,
			})
			.then(() => next())
			.catch(() => next());
	} else {
		next();
	}
});

export default router;
