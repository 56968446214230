<template>
	<div id="navbar">
		<b-navbar toggleable="lg">
			<b-link class="nav-session-back" to="/home" v-if="$route.name === 'Session'">❮</b-link>

			<b-navbar-brand>
				<router-link to="/">
					<img id="logo" src="../assets/logo-sm.svg" alt="놀공스쿨" />
				</router-link>
			</b-navbar-brand>

			<b-navbar-toggle target="nav-collapse">
				<template v-slot:default="{ expanded }">
					<b-icon v-if="expanded" icon="x"></b-icon>
					<b-icon v-else icon="list"></b-icon>
				</template>
			</b-navbar-toggle>

			<b-collapse v-if="signedIn" id="nav-collapse" is-nav>
				<b-navbar-nav class="ml-auto" right>
					<b-nav-item v-if="userName.length" class="nav-user-info" disabled> {{ userName }} 선생님 </b-nav-item>
					<b-nav-item v-if="isApproved && $store.state.kits.includes('월페커즈')" to="/home">수업 관리</b-nav-item>
					<b-nav-item v-if="isApproved" to="/material">수업 자료</b-nav-item>
					<b-nav-item to="/mypage">내 정보</b-nav-item>
					<b-nav-item to="/about">자주 묻는 질문</b-nav-item>
					<b-nav-item class="mobile" href="https://docs.google.com/forms/d/e/1FAIpQLSdhYRq44mvfYfjwAYWeGogU9is54wtgt0puCIqWQ6CIpFZMsg/viewform" target="_blank">의견제안</b-nav-item>
					<b-nav-item class="nav-logout" @click="makeSignOut()">로그아웃</b-nav-item>
				</b-navbar-nav>
			</b-collapse>
			<b-collapse v-else id="nav-collapse" is-nav>
				<b-navbar-nav class="ml-auto" right>
					<b-nav-item to="/about">자주 묻는 질문</b-nav-item>
					<b-nav-item to="/login">로그인</b-nav-item>
				</b-navbar-nav>
			</b-collapse>
		</b-navbar>
	</div>
</template>

<script>
// 내비게이션 바 컴포넌트 (별도의 페이지 아님)
export default {
	name: 'Navbar',
	mounted() {
		this.getProfile(); // 유저 정보 조회 요청
	},
	data() {
		return {
			// 유저 데이터
			userName: '',
			schoolName: '',
			signedIn: false,
			isApproved: false,
		};
	},
	methods: {
		// 유저 정보 조회 요청
		getProfile() {
			this.$store.dispatch('GET_PROFILE').then((res) => {
				if (this.$store.state.serverStatus === 200) {
					// console.info(this.$route)
					// console.info(window.location)
					this.signedIn = true;
					const userProfile = res.data.results.pop();
					this.userName = userProfile.full_name;
					this.schoolName = userProfile.school;
					this.isApproved = userProfile.is_approved;

					/*
            console.log("route_appid p= " + this.$route.params.app_id)
            if (this.$route.params.app_id) {
              this.$store.dispatch(
                  "NOLAUTH_SIGNIN", 
                  {"app_id": this.$route.params.app_id } // "1eb4113c-ea56-48ff-b011-2e8178824c19"}
              ).then((res) => {
                console.log(res)
                if (res.status == 200) {
                  if (res.data.msg === 'login needed') {
                    console.log("need login")
                  }
                  else if (res.data.msg === 'consent needed') {
                    console.log("move to consent")
                    window.location.href = "http://localhost:8000/nolauth/consent_to_use_app?app_id=" + this.$route.params.app_id;
                  } else if (res.data.msg === 'login success') {
                      this.$router.push("/home").catch((ex) => ex);
                  }
                }
              }); 
            }
            */

					if (!this.isApproved && !['/', '/mypage/', '/mypage', '/about/', '/about'].includes(window.location.pathname)) {
						// 관리자 승인 여부 확인, 미승인시 랜딩 페이지로 이동.
						// 회원정보 페이지는 관리자 승인 여부 무관 접속 가능
						this.$router.push('/');
						alert(`${this.userName} 선생님, 가입 승인이 진행 중입니다. 승인이 완료되면 이메일로 안내드리겠습니다.`);
					}

					if (!this.$store.state.kits.includes('월페커즈') && ['/home/', '/home'].includes(window.location.pathname)) {
						// 관리자 승인 여부 확인, 미승인시 랜딩 페이지로 이동.
						// 회원정보 페이지는 관리자 승인 여부 무관 접속 가능
						this.$router.push('/');
						alert(`수업 관리 기능은 월페커즈 프로그램 신청 후 이용하실 수 있습니다.`);
					}

					// if (userProfile.full_name === "") {
					//   alert('수업을 편리하게 신청/관리하기 위해 성명, 연락처, 학교 정보를 등록해주세요.')
					//   this.$router.push('/signupinfo')
					// }
					// console.log(res.data)
				}
				if (this.$store.state.serverStatus === 400) {
					// console.log('failed to get user data')
				}
				// 토큰 기간 만료로 요청 실패
				// if (this.$store.state.serverStatus === 401 && this.$route.path !== '/about') {
				//   this.$router.push('/')
				//   // window.location.reload()
				//   alert('다시 로그인하십시오.')
				// }
			});
		},
		makeSignOut() {
			if (confirm('로그아웃하시겠습니까?')) {
				this.$store.dispatch('SIGNOUT').then(() => {
					this.signedIn = false;
					this.$router.push('/');
				});
			}
		},
	},
};
</script>

<style scoped>
#navbar {
	position: fixed;
	z-index: 10;
	width: 100%;
	background-color: #fff;
	color: #000000;
	box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
	/*min-width: 400px;*/
}
.navbar-brand {
	margin-left: 1em;
	padding: 0;
}
.navbar-toggler {
	right: 1em;
	padding: 0;
	color: rgba(0, 0, 0, 0.5);
	border: none;
}
#logo {
	height: 40px;
	margin-bottom: 3px;
}
#nav-collapse .nav-link {
	color: #000;
}
#nav-collapse .nav-link:hover {
	color: #ff6936;
}
#nav-collapse .nav-logout .nav-link {
	color: #888888;
}
.nav-logout .nav-link:hover {
	color: #ff6936;
}
#nav-collapse .nav-user-info .nav-link {
	color: #888;
}
#nav-collapse .router-link-exact-active {
	/* 현재 위치 */
	color: #ff6936;
	font-weight: 600;
	border-bottom: #ff6936 2px solid;
}
.nav-session-back {
	position: absolute;
	/*top: 1rem;*/
	color: #888888;
}
@media (max-width: 991px) {
	/* 모바일에서 사용될 스타일을 여기에 작성합니다. */
	.navbar-brand {
		margin-left: calc(50% - 120px);
	}
	.navbar-nav {
		margin-top: 12px;
	}
	.nav-item {
		height: 48px;
		margin-left: calc(50% - 120px);
		margin-right: calc(50% - 120px);
		/*display: flex;*/
		/*justify-items: flex-start;*/
		/*border-top: 1px solid rgba(0,0,0,0.1);*/
	}
}
@media (max-width: 576px) {
	#logo {
		width: 200px;
	}
	.navbar-brand {
		margin-left: calc(50% - 100px);
	}
	.nav-item {
		margin-left: calc(50% - 100px);
		margin-right: calc(50% - 100px);
	}
	.nav-session-back {
		top: 0.75rem;
	}
}
</style>
